import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import RoleplayContent from "../components/roleplayContent";
import i18nMessages from '../i18n/sv.json';
import 'intl/locale-data/jsonp/sv';
import PageWrapper from "../components/pageWrapper";

const Roleplay = (props) => {
    
    return (
        <PageWrapper location={props.location} lang="sv" i18nMessages={i18nMessages}>
            <RoleplayContent location={props.location} langUrl="/sv" />
        </PageWrapper>
    )
}

export default Roleplay

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`