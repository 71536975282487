import React, { useState, useRef } from "react"
import { navigate } from 'gatsby';
import Popover from 'react-bootstrap/Popover';
import Overlay from 'react-bootstrap/Overlay';
import AppConfig from '../appconfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle, faBan, faEnvelope, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import anonUser from '../../static/anon_user.png';
import { FormattedMessage } from 'react-intl';



export default function AdBannerGeneral(props) {
	

	return (
		<div>
			<iframe src="//a.exdynsrv.com/iframe.php?idzone=4330202&size=728x90" width="728" height="90" scrolling="no" 
                            marginwidth="0" marginheight="0" frameborder="0"></iframe>
		</div>
	);
	
}



